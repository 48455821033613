.c-color-picker{
    display: flex;
    position: relative;
}

.color-picker-input{
    width: 100px;
    margin-right: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}

.color-picker{
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}
  
.color-picker .triangle{
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-left: 10px solid gray;
    border-bottom: 9px solid transparent;
    margin-right: 5px;
}
  
.color-picker .color{
    margin: 5px;
    width: 25px;
    height: 25px;
    border: 0px solid #fff;
    cursor: pointer;
}

.popover{
    top: auto !important;
    left: 100% !important;
    bottom: 0px !important;
}

.cover{
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}