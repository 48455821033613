.qrcode-input{
    max-width: 1000px;
    width: 70%;
    margin: 30px 10px 10px;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #D2D2D2;
    border: none;
}
  
.c-pattern{
    height: 200px;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.pattern{
    height: 100%;
    cursor: pointer;
    padding: 8px;
}
  
.pattern+ .pattern{
    margin-left: 25px;
}
  
.pattern.selected{
    outline: 2px solid blue;
}
  
.pattern input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.pattern .image{
    height: 100%;
    width: auto;
}