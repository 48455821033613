.background-title{
    font-size: 1em;
    text-align: left;
    padding: 15px 25px;
    margin: 0px;
    color: rgb(80, 81, 85);
    font-weight: bold;
  }
  
.background-grid{
    display: grid;
    padding: 0px 25px;
    grid-template-columns: repeat(auto-fill,minmax(150px, 3fr));
    grid-auto-columns: minmax(auto, 300px);
    grid-gap: 8% 5%;
}
  
.background-grid .item{
    cursor: pointer;
    padding: 10px;
    border: 2px solid #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.6);
    position: relative;
}
  
.background-grid .item.active{
    border: 2px solid #0070D2;
    box-shadow: 0 0 3px 0 #0070BE;
}
  
.background-grid .image{
    width: 100%;
}
  
.background-grid .checkbox{
    position: absolute;
    right: 5%;
    bottom: 5%;
    width: 20px;
    height: 20px;
    border: 1px solid #A4A5A5;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1px;
}

.background-grid.multiselect .item.active .checkbox {
    background-color: #004BBE;
    border: 1px solid #004BBE;
}

.background-grid .check {
    display: none;
    transform: rotate(45deg);
    height: 12px;
    width: 7px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
}

.background-grid.multiselect .item.active .check {
    display: inline-block;
}