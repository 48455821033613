.logo-title{
    font-size: 1em;
    text-align: left;
    padding: 15px 25px;
    margin: 0px;
    color: rgb(80, 81, 85);
    font-weight: bold;
  }
  
  .logo-grid{
    display: grid;
    padding: 0px 25px;
    grid-template-columns: repeat(auto-fill,minmax(100px, 3fr));
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    /* grid-auto-rows: minmax(auto, 200px); */
    grid-auto-columns: minmax(auto, 300px);
    /* grid-gap: 40px; */
    grid-gap: 5%
  }
  
  .logo-grid .item{
    background-color: #F5F2F2;
    cursor: pointer;
    padding: 10px;
    border: 2px solid #F5F2F2;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.6);
    position: relative;
    aspect-ratio: 1 / 1;
  }
  
  .logo-grid .item.active{
    border: 2px solid #0070D2;
    box-shadow: 0 0 3px 0 #0070BE;
  }
  
  .logo-grid .bezel{
    /* border: 1px solid #e0e0e0; */
    border-radius: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px; */
  } 
  
  .logo-grid .bezel.no-logo{
    border: 0px;
  }
  
  .logo-grid .image{
    width: 85%;
  }