.output{
    height: 45%;
    background-color:#C6E9FF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.mobile .output{
    height: 100%;
}
  
.output .title{
    position: absolute;
    left: 25px;
    top: 20px;
    font-size: 1em;
    z-index: 1;
    font-weight: bold;
    color: #3E3E3C;
}
  
.output .logo{
    position: absolute;
    bottom: 20px;
    right: 25px;
}
  
.output .export-button{
    font-size: 0.75em;
    padding: 5px 10px;
    color: #005DB5;
    position: absolute;
    top: 25px;
    right: 25px;
    background-color: white;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.6);
    font-weight: bold;
}
  
.output .canvas-area{
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.mobile .output .canvas-area{
    flex-direction: column;
}
  
.output .export-area{
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-carrusel{
    flex: 1;
    height: 100%;
}
  
.carrusel{
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
}
  
.canvas-template{
    height: calc( 100% - 20px );
    transition: 1s;
    position: absolute;
}
  
.dots-container{
    position: absolute;
    left: 50%;
    bottom: 0px;
    display: flex;
    align-items: center;
    transform: translate(-50%, 0px);
}
  
.dots-container .dot{
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #fff;
}

.dots-container .dot.active{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #AFAFAF;
}
  
.dots-container .dot +.dot{
    margin-left:2px;
}
  
.previous-arrow{
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0px;
    color: #706E6B;
}
  
.next-arrow{
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0px;
    color: #706E6B;
}
  
.qrcode-container{
    height: 100%;
    flex: 1 1 0%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.mobile .qrcode-container{
    height: 300px;
    max-height: 500px;
    width: 100%;
}
  
.canvas-container{
    height: 70%;
    padding: 15px;
    max-width: 320px;
    max-height: 320px;
    min-width: 150px;
    min-height: 150px;
    border: 2px solid #FFFFFF;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 60%);
}