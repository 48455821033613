.App {
  font-family: SalesforceSans-Regular;
  text-align: center;
  display: flex;
  height: 100vh;
  width: 100vw;
}

.main{
  width: 25%;
  max-width: 380px;
  min-width: 260px;
  height: 100%;
  background-color: #007CF5;
}

.qrcode-area{
  flex: 1;
  height: 100%;
  background-color: #f1f1f1;
}

#react-qrcode-logo{
  display: none;
  height: 100%;
}

.App.mobile{
  flex-direction: column;
}

.mobile .main{
  max-width: none;
  height: 54px;
  width: 100%;
}

.mobile .qrcode-area{
  height: calc( 100% - 54px);
}


@media only screen and (max-width: 1440px) {
  html {
    font-size: 15px;
  }
}


@media only screen and (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}