.nav-tabs .nav-link{
  color: black !important;
}

.nav-tabs .nav-link.active{
  background-color: #005DB5 !important;
  border-radius: 0px !important;
  color: white !important;
  font-weight: 700 !important;
  border: none !important;
}

.tab-content{
    height: calc(100% - 42px);
}

.custom_tab-pane{
    height: 100%;
    padding: 20px 15px;
}

.c-section{
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
  
.c-section .section{
    height: 100%;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}    

.c-section .section.l_border{
    border-left: 1px solid rgb(0, 0, 0);
}
  
.product-icons{
    display: flex;
    padding: 0px 25px;
    min-height: 50px;
    width: 100%;
}
  
  .product-icons .icon-container{
    /* width: 50px;
    height: 50px;
    background-color: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 60%);
    display: flex;
    justify-content: center;
    align-items: center; */
  }
  
  .product-icons .icon-container img{
    /* border-radius: 50%;
    padding: 4px;
    border: 1px solid #E5E5E5;
    background-color: #FEFEFE; */
  }
  
.product-icons .icon-container+ .icon-container{
    margin-left: 10px;
}
  
.color_wheel{
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    /* position: relative; */
    /* border: 1px solid #000000; */
    border-radius: 50%;
    height: 100%;
    aspect-ratio: 1;
}
  
.color_wheel .circle{
    position: absolute;
    width: 18%;
    height: 18%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.color_wheel .circle.active{
    border: 1px solid #0176d3;
}
  
.color_wheel .circle .color{
    height: 85%;
    width: 85%;
    border-radius: 50%;
    cursor: pointer;
}
  
.color_wheel .circle.num1{
    top: 0;
    left: calc(54% - 7.5%);
}
  
.color_wheel .circle.num2{
    top: 6%;
    left: calc(72% - 7.5%);
}
  
.color_wheel .circle.num3{
    top: 20%;
    left: calc(86% - 7.5%);
}
  
.color_wheel .circle.num4{
    top: 40%;
    left: calc(93% - 7.5%);
}
  
.color_wheel .circle.num5{
    top: 61%;
    left: calc(88% - 7.5%);
}
  
.color_wheel .circle.num6{
    top: 77%;
    left: calc(74% - 7.5%);
}
  
.color_wheel .circle.num7{
    top: 85%;
    left: calc(54% - 7.5%);
}
  
.color_wheel .circle.num8{
    top: 81%;
    left: calc(33% - 7.5%);
}
  
.color_wheel .circle.num9{
    top: 69%;
    left: calc(17% - 7.5%);
}
  
.color_wheel .circle.num10{
    top: 50%;
    left: calc(8% - 7.5%);
}
  
.color_wheel .circle.num11{
    top: 31%;
    left: calc(9% - 7.5%);
}
  
.color_wheel .circle.num12{
    top: 14%;
    left: calc(19% - 7.5%);
}
.color_wheel .circle.num12 .color{
    background-color: #fff;
    border: 1px solid #000;
}
  
.color_wheel .circle.num13{
    top: 3%;
    left: calc(35% - 7.5%);
}
.color_wheel .circle.num13 .color{
    background: conic-gradient(#fff 0.25turn, #EFEFEF 0.25turn 0.5turn, #fff 0.5turn 0.75turn, #EFEFEF 0.75turn) 
                top left / 25% 25% repeat;
}
  
.detailed-colors{
    display: flex;
    padding: 15px 25px;
    flex-wrap: wrap;
}
  
.circle-color{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.circle-color.active{
    border: 1px solid #0070D2;
}
  
.circle-color .secondary-color{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #000;
    cursor: pointer;
}
  
.circle-color.active .secondary-color{
    height: 52px;
    width: 52px;
}

.custom-color{
    padding-left: 25px;
    margin-top: auto;
    display: inline-block;
}