.tab_content-custom{
    height: 55% !important;
    background-color: #fff;
}

.tab_content-custom .stretch{
    height: 100%;
}

.tab_content-custom .scrollable{
    height: 100%;
    overflow: scroll;
}