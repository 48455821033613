.mobile .nav.collapse{
    display: flex;
}
  
.mobile .nav{
    background-color: blue;
    position: absolute;
    width: 100%;
    display: none;
    z-index: 1;
    top: 54px;
}
  
.top-bar{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
  
.mobile .top-bar{
    flex-direction: row;
}
  
.mobile .top-bar .icon-container{
    cursor: pointer;
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.app-title{
    font-size: 1.8em;
    color: #FFFFFF;
    line-height: 2rem;
    text-align: left;
    margin: 20px 25px 10px;
    font-weight: bold;
}
  
.mobile .app-title{
    font-size: 18px;
    margin: 0px;
}
  
.custom_nav-item{
    display: block;
    /* width: calc(100% - 60px);
    text-align: left;
    padding: 0px 10px; */
}
  
.custom_nav-link{
    margin: 0.7rem 1.5rem;
    /* margin: 10px 25px; */
    padding: 0px !important;
}
  
.custom_nav-link.active{
    background-color: #fff !important;
    border-radius: 5px 0 0 5px !important;
    margin: 0.7rem 0px 0.7rem 1.5rem;
}
  
.custom_nav-link .box{
    width: 100%;
    /* height: 50px; */
    max-height: 60px;
    background-color: #FAFAF9;
    border-radius: 5px;
    /* margin: 10px 25px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom_nav-link.active .box{
      background-color: #fff;
      border-radius: 5px 0 0 5px;
      /* margin: 10px 0px 10px 25px; */
}
  
.mobile .custom_nav-link .box{
    width: 100%;
    margin: 0px;
    border-radius: 0px;
}
  
.custom_nav-link .box .icon-container{
    width: 3.8rem;
    /* height: 100%; */
    padding: 0.5rem;
    border-radius: 5px 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CAE9FF;
    align-self: stretch;
}
  
.custom_nav-link .box .icon-container.custom{
    padding: 0.1rem;
}
  
.custom_nav-link .box .icon-container .icon{
    width: 100%;
    height: 100%;
    color: #A4A5A5;
}

.custom_nav-link .box .text{
    flex-grow: 1;
    padding: 0px 0.5rem;
    color: #505155!important;
    font-size: 1em;
    line-height: 1.375em;
    text-align: left;
}
  
.custom_nav-link.active .box .text{
    font-weight: bold;
}
  
.custom_nav-link.active .box .icon-container{
    background-color: #004BBE;
}

.custom_nav-link.active .box .icon-container svg{
    color: #fff !important;
}

.custom_nav-link .box .icon-container .salesforce-cloud{
    color: #009CDD !important;
}
  
.custom_nav-link.active .box .icon-container .salesforce-cloud{
    color: #009CDD !important;
}
  
.custom_nav-link .box .icon-container .astro path{
    fill: #A4A5A5 !important;
}
  
.custom_nav-link.active .box .icon-container .astro path{
    fill: #fff !important;
}